import * as React from "react";
import { NewsContents, Container, Breadcrumb } from "../../components";
import Seo from "../../components/Seo";

const NewsPage = () => {
  return (
    <Container>
      <Seo
        title="お知らせ"
        description="TKCのニュース一覧ページです。プレスリリース、メディア掲載情報、イベント情報、お知らせなどをご覧いただけます。"
      />
      <NewsContents />
      <Breadcrumb currentPage="News" currentSlug="/news" />
    </Container>
  );
};

export default NewsPage;
